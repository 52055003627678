import styled from 'styled-components';

export const Advanced = styled.div`
  padding-top: 34px;
  @media (min-width: 1025px) {
    padding-top: 19px;
  }
  h3 {
    color: #000000;
    font-size: 14px;
    font-family: 'Lato';
    letter-spacing: 1.56px;
    line-height: 26px;
    text-transform: uppercase;
    font-weight: 900;
  }
  .row {
    margin: 0;
  }
  svg {
    cursor: pointer;
  }
  .check-box-display {
    padding: 0;
    border-bottom: 1px solid #e1e1e1;
    flex-basis: auto;
  }
  .check-box-align {
    padding: 0;
  }
  .filter-items {
    max-height: 230px;
    overflow: auto;
    margin-bottom: 18px;
    .radio-btn {
      .check {
        background-image: url(/images/Remove-Icon.svg);
        background-size: 12px;
        height: 14px;
        width: 12px;
        margin-top: 0;
      }
      input[type='checkBox']:checked ~ .check {
        background-image: url(/images/Remove-Icon.svg);
        background-size: 12px;
        height: 14px;
        width: 12px;
        margin-top: 0;
      }
      input[type='checkbox']:checked ~ label,
      input[type='checkbox']:checked ~ span {
        margin: 0 20px 0 10px;
        text-overflow: ellipsis;
        font-weight: 900;
        overflow: hidden;
        ::before {
          display: none;
        }
      }
    }
  }
  .doc-section {
    .radio-btn {
      label {
        white-space: normal;
      }
    }
  }
  .cond-style {
    max-height: 250px;
    overflow: auto;
  }
  .collapse {
    &.show {
      margin-bottom: 18px;
    }
  }
  .title-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    svg {
      height: 30px;
      width: 30px;
      color: #eb3c96;
    }
    &:hover {
      svg {
        color: #a52a69;
      }
    }
  }
  .cond-parent {
    label {
      color: #000000;
      font-size: 16px;
      margin-top: 10px;
      line-height: 27px;
      font-family: Lato;
      font-weight: 900 !important;
    }
  }
  p.inactive {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }
  p.active {
    font-size: 16px;
    color: #eb3c96;
    font-weight: 900;
    ::before {
      content: ' ';
      z-index: 1;
      border-left: 5px solid #eb3c96;
      height: 20px;
      top: 5px;
      margin-right: 8px;
    }
  }
  .cond-div {
    .radio-btn .check {
      background-image: none;
    }
  }
  .radio-btn {
    label {
      margin: 0 0 2px 12px !important;
    }
    input[type='checkbox']:checked ~ label,
    input[type='checkbox']:checked ~ span {
      color: #eb3c96;
      ::before {
        content: ' ';
        position: absolute;
        z-index: 1;
        border-left: 5px solid #eb3c96;
        height: 20px;
        left: -10px;
        top: 5px;
      }
    }
    input[type='checkBox']:checked ~ .check {
      background-image: none;
    }
  }
  .reset-button {
    margin: 40px 0;
    background-color: #eb3c96;
    height: 38px;
    width: 100px;
    color: #ffffff;
    font-size: 11px;
    letter-spacing: 2px;
    text-align: center;
    font-family: 'Lato';
    font-weight: 900;
    border: none;
    text-transform: uppercase;
    :hover {
      background-color: #a52a69;
    }
  }
`;
