import styled from 'styled-components';

export const CheckBoxStyle = styled.div`
.radio-btn {
  color: #333333;
  font-size: 21px;
  line-height: 29px;
  display: inline-block;
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    visibility: hidden;
  }
  label, span {
    display: block;
    position: relative;
    white-space: nowrap
    font-weight: 300;
    font-size: 16px;
    font-family: 'Lato';
    margin: 0 20px 5px 5px;
    padding: 0;
    color: #000000;
    line-height: 27px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }
  .check {
    background-image: url(/images/Default.png);
    background-position-x: 0;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position-y: 2px;
    height: 24px;
    margin-top: -5px;
    width: 24px;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .check::before {
    display: block;
    position: absolute;
    content: '';
    // border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
  input[type='checkBox']:checked ~ .check {
    background-image: url(/images/Active.png);
    background-position-x: 0;
    background-repeat: no-repeat;
    background-size: 18px;
    background-position-y: 2px;
    height: 24px;
    width: 24px;
    top: 0;
    left: 0;
    z-index: 5;
  }
  input[type='checkBox']:checked ~ .radio-label {
    color: #0dff92;
  }
}
.genderClass {
  display: flex;
  align-items: center;
}
.col-age {
  padding-left: 0;
}
.padding-correction {
  padding: 0 20px 0 35px;
}
@media (min-width: 320px) and (max-width: 480px) {
  label {
    font-size: 16px !important;
  }
}
`;