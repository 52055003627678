import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const ResultsPagination = ({
  simple,
  onChange,
  totalItemsCount,
  itemsCountPerPage,
  activePage,
}) => {
  if (simple) {
    let numberOfPages = Math.ceil(totalItemsCount / itemsCountPerPage);

    return (
      <div className="simple-pagination">
        {activePage > 1 && (
          <button
            onClick={() => onChange && onChange(activePage - 1)}
            className="pagination-arrow left"
            type="button"
          >
            <IoIosArrowBack />
          </button>
        )}
        <div className={'counters'}>
          {activePage < numberOfPages ? (
            <span className="current-page">{activePage}</span>
          ) : (
            undefined
          )}
          {activePage < numberOfPages ? <em> - </em> : undefined}
          <span className="current-page">
            {numberOfPages > 1 ? numberOfPages : null}
          </span>
        </div>
        {activePage < numberOfPages && (
          <button
            onClick={() => onChange && onChange(activePage + 1)}
            className="pagination-arrow right"
            type="button"
          >
            <IoIosArrowForward />
          </button>
        )}
      </div>
    );
  } else {
    return (
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        nextPageText="NEXT"
        prevPageText="PREVIOUS"
        hideFirstLastPages={true}
        pageRangeDisplayed={4}
        itemClass="page-item"
        linkClass="page-link"
        linkClassNext="text-link"
        linkClassPrev="text-link"
        onChange={onChange}
      />
    );
  }
};

ResultsPagination.propTypes = {
  simple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
};

ResultsPagination.defaultProps = {
  simple: false,
};

export default ResultsPagination;
