import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Label } from 'reactstrap';
import { CheckBoxStyle } from './checkbox.css';
import { trackEvent } from '../../helpers/tagManager';

const CheckboxField = (props) => {
  const {
    id,
    value,
    displayvalue: displayValue,
    name,
    special,
    condchildren: condChildren,
    field,
    form,
  } = props;

  const onChange = () => {
    let nextValue = field.value
    if (field.value && field.value.includes(value)) {
      nextValue = field.value.filter((oldVal) => oldVal !== value);
      if (condChildren) {
        condChildren.forEach((child) => {
          if (field.value.includes(child)) {
            nextValue = nextValue.filter((oldVal) => oldVal !== child);
          }
        });
      }
    } else {
      nextValue = field.value.concat(value);
      if (condChildren) {
        condChildren.forEach((child) => {
          nextValue = nextValue.concat(child);
        });
      }
      trackEvent({
        category: 'SiteSearch',
        action: 'Suggestion_Click',
        label: displayValue,
        eventActionType: name,
      });
    }
    form.setFieldValue(name, nextValue);
  };

  return (
    <CheckBoxStyle>
      <Label className={'radio-btn genderClass'} for={id} >
        <input
          type="checkbox"
          {...props}
          checked={field.value && field.value.includes(value)}
          onChange={onChange}
          id={id}
        />
        <div hidden={special} className={'check'}>
          <div className={'inside'} />
        </div>
        {<span className={'padding-correction'}>{displayValue}</span>}
      </Label>
    </CheckBoxStyle>
  );
}

const Checkbox = (props) => (
  <Field name={props.name}>
    {({ field, form }) => (
      <CheckboxField field={field} form={form} {...props} />
    )}
  </Field>
);

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  displayvalue: PropTypes.string,
  special: PropTypes.bool,
  condchildren: PropTypes.array,
};

CheckboxField.propTypes = {
  ...Checkbox.propTypes,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default Checkbox;
