/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { navigate as reactNavigate } from '@reach/router';
import axios from 'axios';
import { graphql, Link } from 'gatsby';
import { GoogleApiWrapper } from 'google-maps-react';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ClampLines from 'react-clamp-lines';
import {
  FaAngleDown,
  FaAngleUp,
  FaMinusCircle,
  FaPlusCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Col, Collapse, Row } from 'reactstrap';
import DownloadSection from '../components/documents-popup/download-section/download-section-results';
import Layout from '../components/layout/layout';
import Modal from '../components/modal/modal';
import Redirect from '../components/redirect/redirect';
import Advanced from '../components/search/advanced-search';
import FindATrial from '../components/search/find-a-trial';
import Pagination from '../components/search/pagination';
import SocialSharing from '../components/social-sharing/social-sharing';
import {
  getCompanyData,
  gMapApiKey,
  replaceCompanyName,
  searchTrialUrl,
} from '../constants/config';
import {
  FilterDiv,
  FilterMobile,
  Fonts,
  ResultsFindTrial,
  ResultsGeneralStyle,
  ResultsTable,
} from '../global.css';
import {
  filterSearchEventProperties,
  trackAnalytics,
} from '../helpers/trackAnalytics';
import { trackEvent } from '../helpers/tagManager';

const TrialResults = ({ location, data, pageContext, navigate }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [showExternalLinkModal, setShowExternalLinkModal] = useState('');

  let markers = [];
  let cluster = null;

  useEffect(() => {
    makeSearchRequest(getSearchPayload());
    if (typeof window !== 'undefined') {
      setIsLoading(false);
    }
    if (markers && markers.length && cluster) {
      markers.forEach((marker) => {
        cluster.removeMarker(marker);
      });
    }
    if (data && data.searchResultJson) {
      data.trialsContent = data.searchResultJson;
    }
  }, []);

  const getSearchPayload = () => {
    let tempSearchParams = null;
    try {
      tempSearchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      tempSearchParams = new URLSearchParams('');
    }
    setSearchParams(tempSearchParams);

    let params = {
      PageSize: 16,
      PageIndex: 0,
      SortField: 'Location_Distance',
      SortOrder: 'asc',
      SearchTerm: '',
      Status: '',
      Longitude: '',
      Latitude: '',
      AgeRanges: '',
      Conditions: '',
      TherapeuticArea: '',
      Treatment: '',
      AttachmentTypes: '',
      Phases: '',
      Gender: '',
      HealthyVolunteer: '',
      PatientLevelSharing: '',
      StudyTypes: '',
      StudyResults: '',
      Products: '',
      Collaborators: '',
      MileRadius: '',
      UseAndOperatorForFullTextKeyWordSearch: false,
      zip: '',
      country: '',
      PatientLevelData: '',
      HasResults: '',
    };

    Object.keys(params).forEach((key) => {
      if (tempSearchParams.get(key)) {
        params[key] = tempSearchParams.get(key);
      }
    });

    if (params.country.length && !params.zip.length) {
      params.Latitude = '';
      params.Longitude = '';
      params.Countries = params.country;
    }

    return params;
  };

  const showFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const showModal = (index) => {
    setSearchResults(
      searchResults.map((item, idx) => {
        return {
          ...item,
          modalFlag: idx === index,
        };
      })
    );
  };

  const hideModal = () => {
    setSearchResults(
      searchResults.map((item) => {
        return {
          ...item,
          modalFlag: false,
        };
      })
    );
    setShowExternalLinkModal('');
  };

  const makeSearchRequest = (tempSearchParams) => {
    setSearchParams(tempSearchParams);
    NProgress.start();

    if (markers && markers.length && cluster) {
      markers.forEach((marker) => {
        cluster.removeMarker(marker);
      });
    }

    const eventParams = filterSearchEventProperties(tempSearchParams);
    axios
      .post(searchTrialUrl(), tempSearchParams)
      .then((res) => {
        NProgress.done();
        const tempSearchResults = res.data.Data.map((row) => {
          return {
            ...row,
            toggleFlag: false,
          };
        });

        trackAnalytics('SearchResponse', {
          Count: res.data.Count,
          Success: res.data.Success,
          Context: res.data.Context,
          ...eventParams,
        });

        if (
          tempSearchParams.SearchTerm &&
          tempSearchParams.SearchTerm.length > 0
        ) {
          trackEvent({
            category: 'SiteSearch',
            action: 'SearchTerm_Entry',
            label: tempSearchParams.SearchTerm,
            value: res.data.Count,
          });
        }

        if (res.data.Success) {
          let tempTitleToggle = {};
          res.data.Data.forEach((row) => {
            tempTitleToggle[row.Id] = false;
          });

          markers = [];
          setSearchResults(tempSearchResults);
          setTotalResults(res.data.Count);
        }
      })
      .catch((error) => {
        trackAnalytics('SearchResponseFailure', {
          Error: error.message,
          Count: -1,
          Success: false,
          Context: '',
          ...eventParams,
        });
      });
  };

  const orderByField = (fieldName, direction = 'asc') => {
    let tempSearchParams = searchParams;
    tempSearchParams.SortField = fieldName;
    tempSearchParams.SortOrder = direction;
    tempSearchParams.PageIndex = 0;
    makeSearchRequest(tempSearchParams);
    trackAnalytics('SearchOrder', {
      Field: fieldName,
      Direction: direction,
    });
  };

  const trackLink = (event, attachment, row) => {
    trackAnalytics('DocumentDownload', {
      attachment: attachment.TypeInternal,
      TrialId: row.UniqueIdentifier,
      TrialTitle: row.Title,
      TrialStatus: row.StatusInternal,
      Conditions: row.Conditions.map((cond) => cond.InternalValue),
    });

    if (event.target && typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach((_ga) => {
          _ga.send('event', 'Downloads', 'Downloads', event.target.href);
        });
      } catch (ev) {
        console.log(ev);
      }
    }
  };

  const goToPage = (pageNumber) => {
    let tempSearchParams = searchParams;
    tempSearchParams.PageIndex = pageNumber - 1;
    makeSearchRequest(tempSearchParams);
    if (window.innerWidth > 482) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 400);
    }
  };

  const buildURL = () => {
    if (typeof window !== 'undefined' && searchParams !== null) {
      let url = Object.keys(searchParams)
        .map(
          (k) =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(searchParams[k])
                ? searchParams[k].join('~')
                : `${searchParams[k]}`
            )
        )
        .join('&');
      return window.location.origin + window.location.pathname + '?' + `${url}`;
    }
  };

  const buildDisplayFilters = () => {
    if (
      typeof window !== 'undefined' &&
      window.manifest &&
      window.manifest.masterData
    ) {
      data.site.siteMetadata.masterData = window.manifest.masterData;
    }

    let displayData = [];

    if (!searchParams) {
      return [];
    }

    Object.values(searchParams).forEach((selectedValue) => {
      let values = `${selectedValue}`.split('~');
      if (values.length) {
        values.forEach((internalValue) => {
          Object.values(data.site.siteMetadata.masterData.Data).forEach(
            (dataSection) => {
              if (Array.isArray(dataSection)) {
                dataSection.forEach((item) => {
                  if (item.InternalValue === internalValue) {
                    if (
                      item.DisplayValue === 'No' ||
                      item.DisplayValue === 'Yes'
                    ) {
                      displayData.push(
                        'Accepting Healthy Volunteers: ' + item.DisplayValue
                      );
                    } else {
                      displayData.push(item.DisplayValue);
                    }
                  }

                  if (item.Children && Array.isArray(item.Children)) {
                    item.Children.forEach((subItem) => {
                      if (subItem.InternalValue === internalValue) {
                        displayData.push(subItem.DisplayValue);
                      }
                    });
                  }
                });
              }
            }
          );
        });
      }
    });

    if (searchParams.SearchTerm && searchParams.SearchTerm.length) {
      displayData.push(`Keyword: "${searchParams.SearchTerm}"`);
    }

    if (searchParams.zip) {
      displayData.push(searchParams.zip);
    }

    if (searchParams.MileRadius && displayData.length && searchParams.country) {
      displayData.push(`${searchParams.MileRadius} miles`);
    }

    if (!displayData.length) {
      displayData.push('No filter');
    }

    return displayData;
  };

  const filteringCondition = (conditions) => {
    let uniqCondition = [];
    return conditions.filter((cond) => {
      if (!uniqCondition.includes(cond.DisplayValue)) {
        uniqCondition.push(cond.DisplayValue);
        return true;
      }
      return false;
    });
  };

  const renderTableRow = (row, index) => {
    const getIndividualPatientData = () => {
      if (!row.PatientLevelData) {
        return null;
      }
      return {
        href: getCompanyData(data.trialDetailsJson, 'individualPatientDataUrl'),
        onClick: (ev) => {
          trackLink(ev, { TypeInternal: 'INDIVIDUAL_PATIENT_DATA' }, row);
        },
      };
    };

    const getCTGovResults = () => {
      if ((!row.HasResults && !row.NCTID) || (!row.HasResults && row.NCTID)) {
        return null;
      }
      return {
        href: `https://clinicaltrials.gov/ct2/show/results/${row.NCTID}?term=${row.NCTID}&rank=1`,
        onClick: (ev) => {
          ev.preventDefault();
          setShowExternalLinkModal(ev.target.href);
          trackLink(ev, { TypeInternal: 'STUDY_WITH_RESULTS' }, row);
        },
      };
    };

    return [
      <Tr
        key={'result_' + row.Id}
        className={
          'result-row ' + (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
        }
      >
        <Td className={'condition-section'}>
          {row.Conditions
            ? filteringCondition(row.Conditions)
                .map((condition) => condition.DisplayValue)
                .join(', ')
            : undefined}
        </Td>
        <Td className={'status-section'}>
          {row.StatusDisplay === 'Terminated/Withdrawn' ? (
            <div
              className={
                'status-icons ' + `${row.StatusInternal}`.toLocaleLowerCase()
              }
            >
              Terminated/ Withdrawn
            </div>
          ) : (
            <div
              className={
                'status-icons ' + `${row.StatusInternal}`.toLocaleLowerCase()
              }
            >
              {row.StatusDisplay}
            </div>
          )}
        </Td>
        <Td className={'requirements-section'}>
          <div
            className={
              ['All', 'Both'].indexOf(row.GenderLookup) >= 0
                ? 'both' + ' ageRange'
                : `${row.GenderLookup}`.toLocaleLowerCase() + ' ageRange'
            }
          >
            {' '}
            {row.MinAge && row.MaxAge
              ? `${row.MinAge}` +
                (row.MinAgeRaw
                  ? row.MinAgeRaw.includes('Months')
                    ? ' Months'
                    : ' Years'
                  : ' Years') +
                '-' +
                `${row.MaxAge}` +
                (row.MaxAgeRaw
                  ? row.MaxAgeRaw.includes('Months')
                    ? ' Months'
                    : ' Years'
                  : ' Years')
              : row.MinAge
              ? `${row.MinAge}+` +
                (row.MinAgeRaw
                  ? row.MinAgeRaw.includes('Months')
                    ? ' Months'
                    : ' Years'
                  : ' Years')
              : row.MaxAge
              ? `up to ${row.MaxAge}` +
                (row.MaxAgeRaw
                  ? row.MaxAgeRaw.includes('Months')
                    ? ' Months'
                    : ' Years'
                  : ' Years')
              : '0+ Years'}
          </div>
          {row.GenderLookup === 'All' || row.GenderLookup === 'Both' ? (
            <div className={'genderLookup'}>Female & Male</div>
          ) : (
            <div className={'genderLookup'}>{row.GenderDisplay}</div>
          )}
        </Td>
        <Td
          className={
            'distance-section' +
            (!row.Location_Distance && !searchParams.Longitude
              ? ' alt-text '
              : '')
          }
        >
          {row.Location_Distance !== undefined && row.Location_Distance !== null
            ? `${row.Location_Distance.toFixed(0)} ${
                row.Location_Distance.toFixed(0) === (1).toFixed(0)
                  ? 'mile'
                  : 'miles'
              }`
            : searchParams.Longitude && ('' + searchParams.Longitude).length
            ? undefined
            : data.searchResultJson.noAddressSpecified}
        </Td>
        <Td className={'details-section'}>
          <Link
            className={'learn-more learn-mobile'}
            onClick={() => {
              trackAnalytics('TrialDetailsClick', {
                TrialId: row.UniqueIdentifier,
                PositionInList: index,
                TrialTitle: row.Title,
                TrialStatus: row.StatusInternal,
                Conditions: row.Conditions.map(
                  (condition) => condition.InternalValue
                ),
              });
            }}
            to={
              `/${pageContext.langKey}/trial-details/?id=${row.UniqueIdentifier}` +
              (searchParams.Latitude &&
              searchParams.Latitude.length &&
              searchParams.Latitude.length
                ? `&searchLat=${row.Location_Latitude}&searchLong=${row.Location_Longitude}&name=${row.Location_Name}`
                : '') +
              (searchParams.MileRadius &&
              searchParams.MileRadius.length &&
              searchParams.Longitude &&
              searchParams.Longitude.length &&
              searchParams.Longitude.length
                ? `&searchRadius=${searchParams.MileRadius}&baseLat=${searchParams.Latitude}&baseLong=${searchParams.Longitude}`
                : '')
            }
          >
            Learn More
          </Link>
          {row.StatusDisplay === 'Terminated (Halted Prematurely)' ? (
            <div />
          ) : (row.Attachments !== null && Array.isArray(row.Attachments)) ||
            row.PatientLevelData ||
            (row.HasResults && row.NCTID) ? (
            <Modal
              open={row.modalFlag}
              showModal={() => showModal(index)}
              hideModal={() => hideModal(index)}
              button="View Results"
              buttonType="simple"
              type={`view-results ${
                !(getIndividualPatientData() || getCTGovResults())
                  ? 'only-docs'
                  : ''
              }`}
              trackEvent={{
                label: 'View Results',
              }}
            >
              {!showExternalLinkModal ? (
                <div className={'documents-modal'}>
                  <FaTimesCircle
                    onClick={() => hideModal(index)}
                    className={'closeSvg'}
                  />
                  <DownloadSection
                    labels={data.searchResultJson.viewResultsModal}
                    attachments={row.Attachments}
                    onClickDownload={(ev, attachment) =>
                      trackLink(ev, { TypeInternal: attachment }, row)
                    }
                    individualPatientData={getIndividualPatientData()}
                    CTGovResults={getCTGovResults()}
                  />
                </div>
              ) : (
                <Redirect
                  hideModal={() => hideModal(index)}
                  url={showExternalLinkModal}
                />
              )}
            </Modal>
          ) : (
            ''
          )}
        </Td>
      </Tr>,
      <Tr
        key={'result_second_row' + row.Id}
        className={
          'result-second-row ' +
          (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
        }
      >
        <Td colSpan="5">
          <div
            className="more-information"
            data-content={`${row.UniqueIdentifier} - ${row.Title}`}
          >
            <span>{row.UniqueIdentifier} </span>
            <ClampLines
              text={`- ${row.Title}`}
              id="text"
              lines={1}
              innerElement="div"
              ellipsis="..."
              moreText={
                <>
                  <span className={'accesibility-feature'}>more</span>
                  <FaPlusCircle />
                </>
              }
              lessText={
                <>
                  <span className={'accesibility-feature'}>more</span>
                  <FaMinusCircle />
                </>
              }
            />
          </div>
        </Td>
      </Tr>,
    ];
  };

  const renderResultsTable = () => {
    if (!totalResults) {
      return undefined;
    }
    return (
      <div style={{ minHeight: '20vh' }} className={'result-table'}>
        <div className={'start-table'}>
          <Table className="results-table">
            {data.searchResultJson.tableHeader.map((headerItem, index) => (
              <Thead className={'table-header'} key={index}>
                <Tr>
                  <Th className={'conditions desktop'}>
                    {headerItem.conditions}
                  </Th>
                  <Th
                    className={'sortable'}
                    onClick={() =>
                      orderByField(
                        'StatusDisplay',
                        searchParams.SortField === 'StatusDisplay'
                          ? searchParams.SortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      )
                    }
                  >
                    {headerItem.status}
                    {searchParams.SortField === 'StatusDisplay' ? (
                      searchParams.SortOrder === 'asc' ? (
                        <FaAngleDown />
                      ) : (
                        <FaAngleUp />
                      )
                    ) : (
                      ''
                    )}
                  </Th>
                  <Th
                    className={'sortable'}
                    onClick={() =>
                      orderByField(
                        'MinAge',
                        searchParams.SortField === 'MinAge'
                          ? searchParams.SortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      )
                    }
                  >
                    {headerItem.requirements}
                    {searchParams.SortField === 'MinAge' ? (
                      searchParams.SortOrder === 'asc' ? (
                        <FaAngleDown />
                      ) : (
                        <FaAngleUp />
                      )
                    ) : (
                      ''
                    )}
                  </Th>
                  <Th
                    className={'sortable location'}
                    onClick={() =>
                      orderByField(
                        'Location_Distance',
                        searchParams.SortField === 'Location_Distance'
                          ? searchParams.SortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      )
                    }
                  >
                    {headerItem.distance}
                    {searchParams.SortField === 'Location_Distance' ? (
                      searchParams.SortOrder === 'asc' ? (
                        <FaAngleDown />
                      ) : (
                        <FaAngleUp />
                      )
                    ) : (
                      ''
                    )}
                  </Th>
                  <Th>
                    <span className={'accesibility-feature'}>more</span>&nbsp;
                  </Th>
                </Tr>
              </Thead>
            ))}

            <Tbody className={'table-body'}>
              {searchResults.map(renderTableRow)}

              {totalResults ? (
                <Tr className={'pagination-bottom'}>
                  <Td colSpan={8}>
                    <div>
                      {totalResults !== null ? (
                        <Row>
                          {totalResults <= 16 ? (
                            <Col
                              className={
                                totalResults > 16
                                  ? 'pagination-info bottom-info'
                                  : ' pagination-info onePage bottom'
                              }
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              {' '}
                              {'Showing '}{' '}
                              {searchResults && searchResults.length
                                ? `${
                                    searchParams.PageIndex *
                                      searchParams.PageSize +
                                    1
                                  }
                      - ${Math.min(
                        searchParams.PageIndex * searchParams.PageSize +
                          searchParams.PageSize,
                        totalResults
                      )} of `
                                : undefined}
                              {totalResults} {data.searchResultJson.results}
                            </Col>
                          ) : undefined}

                          {totalResults > 16 ? (
                            <Col
                              className={
                                totalResults > 16
                                  ? 'pagination-col bottom-line'
                                  : 'onePageCol bottom'
                              }
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Pagination
                                simple={false}
                                activePage={searchParams.PageIndex + 1}
                                itemsCountPerPage={searchParams.PageSize}
                                totalItemsCount={totalResults}
                                onChange={goToPage}
                              />
                            </Col>
                          ) : undefined}
                        </Row>
                      ) : undefined}
                    </div>
                  </Td>
                </Tr>
              ) : undefined}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  };

  let displayFilters = buildDisplayFilters();
  let canonical = buildURL();

  return isLoading ? (
    ''
  ) : (
    <div>
      {isShowFilter === false ? (
        <Layout
          meta={replaceCompanyName({
            pageTitle: '%%COMPANYNAME%% - Clinical trials results',
            pageDescription: `${
              displayFilters.length
                ? 'Clinical trials for: '
                : 'All clinical trials for %%COMPANYNAME%%'
            }${displayFilters.join(', ')}`,
            canonical: canonical,
            index: totalResults > 0 ? 'index' : 'noindex',
          })}
        >
          <Fonts>
            <div className={'flex-box-container'}>
              <ResultsGeneralStyle style={{ width: '100%' }}>
                <Col
                  lg={3}
                  md={12}
                  sm={12}
                  className="filter-section + deskContent"
                >
                  {/* Advanced Search Section with Filter */}
                  <Advanced
                    location={location}
                    pageContext={pageContext}
                    searchCallBack={() => {
                      makeSearchRequest(getSearchPayload());
                    }}
                  />
                </Col>
                <Col className={'resultsTable-col'} lg={9} md={12} sm={12}>
                  <Row style={{ width: 'auto' }}>
                    <Col md={12} className={'heroSection'}>
                      <Row className={'hero-row'}>
                        <Col
                          tag="h1"
                          lg={9}
                          md={12}
                          sm={12}
                          className={'result-title'}
                        >
                          {data.searchResultJson.title}
                        </Col>
                        <Col
                          lg={3}
                          md={12}
                          sm={12}
                          className={'filter-modal-btn'}
                        >
                          <FilterMobile>
                            <button
                              onClick={showFilter}
                              className={'filter-mobile'}
                            >
                              <img src="/images/filter.svg" alt={'filter'} />
                              Filter
                            </button>
                          </FilterMobile>
                        </Col>
                        <Col xs={12} lg={3} className="social-sharing">
                          <SocialSharing
                            mailSubject={replaceCompanyName(
                              'Studies on %%COMPANYNAME%% Trial Finder'
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={12} lg={12} md={12} className={'search section'}>
                      <ResultsFindTrial className={'title-section'}>
                        <FindATrial
                          isLight={true}
                          navigate={navigate}
                          location={location}
                          pageContext={pageContext}
                          searchCallBack={() => {
                            makeSearchRequest(getSearchPayload());
                          }}
                        />
                      </ResultsFindTrial>
                    </Col>
                    <Col md={12}>
                      <div>
                        <div className="container-fluid">
                          {totalResults ? (
                            <div>
                              {totalResults !== null ? (
                                <Row>
                                  <Col
                                    className={
                                      totalResults > 16
                                        ? 'pagination-info top'
                                        : ' pagination-info onePage'
                                    }
                                    md={12}
                                    sm={12}
                                    xs={12}
                                  >
                                    {'Showing '}{' '}
                                    {searchResults && searchResults.length
                                      ? `${
                                          searchParams.PageIndex *
                                            searchParams.PageSize +
                                          1
                                        }
                          - ${Math.min(
                            searchParams.PageIndex * searchParams.PageSize +
                              searchParams.PageSize,
                            totalResults
                          )} of `
                                      : undefined}
                                    {totalResults}{' '}
                                    {data.searchResultJson.results}
                                  </Col>
                                </Row>
                              ) : undefined}
                            </div>
                          ) : undefined}
                          <ResultsTable>
                            {totalResults >= 1 ? (
                              <div />
                            ) : totalResults === 0 ? (
                              <div className={'no-results-message'}>
                                Sorry, the criteria specified did not match any
                                Trials
                              </div>
                            ) : undefined}
                            {/*Aici vine tabelul de results*/}
                            {renderResultsTable()}
                          </ResultsTable>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </ResultsGeneralStyle>
            </div>
          </Fonts>
        </Layout>
      ) : null}
      <Collapse isOpen={isShowFilter}>
        <FilterDiv>
          <div className={'header-sec'}>
            <IoIosClose onClick={showFilter} />
          </div>
          <div className={'content-sec'}>
            <h2>Filter Results</h2>
            <Advanced
              location={location}
              pageContext={pageContext}
              searchCallBack={() => {
                makeSearchRequest(getSearchPayload());
              }}
            />
          </div>
          <div className={'footer-sec'}>
            <button className={'apply'} onClick={showFilter}>
              Apply Filters
            </button>
            <button
              className={'reset'}
              type="button"
              onClick={() => {
                reactNavigate(
                  '/en/results/?AgeRanges=&Phases=&AttachmentTypes=&SearchTerm=&Gender=&Status=&document=&Conditions=&MileRadius=100&country=&zip=&HealthyVolunteer=&StudyResults=&StudyTypes='
                ) &&
                  window &&
                  window.location.reload();
              }}
            >
              Reset Filters
            </button>
          </div>
        </FilterDiv>
      </Collapse>
    </div>
  );
};

TrialResults.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func,
  google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
  apiKey: gMapApiKey(),
})(TrialResults);

export const query = graphql`
  query SearchResultsQuery {
    searchResultJson {
      title
      socialTitle
      viewDetails
      askToJoinBtn
      tableHeader {
        status
        title
        conditions
        distance
        requirements
      }
      showResult
      results
      noAddressSpecified
      viewResultsModal {
        title
        dropdownDocsTitle
        dropdownDocsPlaceholder
        dropdownLanguageTitle
        dropdownLanguagePlaceholder
        downloadBtn
        descCTGovResults
        labelCTGovResults
        descIndividualPatientData
        labelIndividualPatientData
        completeDownloadTitle
        completeDownloadDesc
        completeDownloadBtn
        notAvailable
      }
    }
    trialDetailsJson {
      individualPatientDataUrl
      individualPatientDataUrl_merck
    }
    site {
      siteMetadata {
        masterData {
          Data {
            RECSTATUS {
              InternalValue
              DisplayValue
              SortOrder
            }
            ATTACHTYPE {
              InternalValue
              DisplayValue
              SortOrder
            }
            AGERANGE {
              InternalValue
              DisplayValue
              SortOrder
            }
            GENDER {
              InternalValue
              DisplayValue
              SortOrder
            }
            HEALTHY_VOL {
              InternalValue
              DisplayValue
              SortOrder
            }
            COUNTRY {
              InternalValue
              DisplayValue
              SortOrder
            }
            PHASE {
              InternalValue
              DisplayValue
              SortOrder
            }
            CONDITION {
              InternalValue
              DisplayValue
              SortOrder
              Children {
                InternalValue
                DisplayValue
                SortOrder
              }
            }
          }
        }
      }
    }
  }
`;
